import React, { useState } from "react";
import { Input, Button, notification } from "antd";
import "./style.css";
import logo from "../../../Assets/logo.png";
import { enterWaitlist } from "../../../API";

const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: "topRight",
    });
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const onJoinClick = async () => {
    if (!email) {
      openNotification("warning", "Invalid Input", "Please enter your email address.");
      return;
    }

    if (!validateEmail(email)) {
      openNotification("error", "Invalid Email Address", "Please enter a valid email address.");
      return;
    }

    setLoading(true);
    try {
      const res = await enterWaitlist({ email });

      if (res.status === 201) {
        openNotification("success", "Success", "You have successfully joined the waitlist.");
        setEmail("");
      } else {
        openNotification("error", "Error", "There was a problem during the join process. Please try again.");
      }
    } catch (error) {
      openNotification("error", "Error", "There is an issue connecting to the server.");
    }
    setLoading(false);
  };

  return (
    <div className="coming-soon-container">
      {/* Logo */}
      <img src={logo} alt="Stynxio Logo" className="logo" />

      {/* Main Content */}
      <div className="main-content">
        <h1 className="title gradient-heading">
          AI-Based <span className="highlight">Realtime Voice & Face Changer</span>
        </h1>
        <p className="description">
          Experience cutting-edge real-time AI technology for face and voice transformation.
        </p>
        <p className="waitlist-detail">
          Join our waitlist now to enjoy <strong>early access</strong>, <strong>exclusive discounts</strong>,
          and <strong>special offers</strong> when we launch!
        </p>
        <div className="waitlist">
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
            className="email-input"
          />
          <Button
            type="primary"
            className="join-button gradient-button"
            onClick={onJoinClick}
            loading={loading}
          >
            Join Waitlist
          </Button>
        </div>
      </div>

      {/* Footer */}
      <footer className="footer">&copy; 2024 Stynxio. All Rights Reserved.</footer>
    </div>
  );
};

export default ComingSoon;
