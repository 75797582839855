import AppRoutes from "./Routes/AppRoutes";
import "./App.css";
import ReactGA from "react-ga4";
import { ConfigProvider } from "antd";
import { useEffect } from "react";

ReactGA.initialize("G-JLKJP70Y71");
function App() {
  useEffect(() => {
    ReactGA.event("Web Access");
  }, []);
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorBgBase: "#252525",
            colorBgContainer: "#333",
            colorBgElevated: "#000",
          },
        }}
      >
        <AppRoutes />
      </ConfigProvider>
    </div>
  );
}

export default App;
